import React, { useEffect } from 'react'
import { Box, Button, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { useParams } from 'react-router-dom'
import * as XLSX from 'xlsx'
import AltRouteIcon from '@mui/icons-material/AltRoute'
import { EntityId } from '@reduxjs/toolkit'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'

import { createApiCall, useDispatchStdApiCall } from '../../store/common/standard-request'
import { PageError, StyledTableCell, StyledTableRow } from '../../components'
import { appSelectors } from '../../store/AppStore'
import { useAppSelector } from '../../store'
import Trace from './ShowTrace'
import { decisionTreeVersionApi, decisionTreeVersionSelectors } from '../../store/DecisionTreeVersionStore'
import { ExecutionTrace, TreeNode } from './ShowTrace/TreeNodeTypes'

export interface ITestResult {
  sequence: number
  name: string
  isSuccess: boolean
  expectedOutput: { [key: string]: any }
  outputValues: { [key: string]: any }
  traces: any
  error: any
}

function index() {
  const { treeId, versionId } = useParams()
  const dropLabel = 'Drop file here'
  const hoverLabel = 'Click or drag to upload file'

  const [labelText, setLabelText] = React.useState<string>(hoverLabel)
  const [fileName, setFileName] = React.useState<string>()
  const [file, setFile] = React.useState<any>(undefined)
  const [testResult, setTestResult] = React.useState<Array<ITestResult>>([])
  const [exceptKey, setExceptKey] = React.useState<Array<string>>([])
  const [showTrace, setShowTrace] = React.useState<boolean>(false)
  const [executionTrace, setExecutionTrace] = React.useState<ExecutionTrace>()

  const dispatchApi = useDispatchStdApiCall()
  const pageError = useAppSelector(appSelectors.getBusinessError)

  const decisionTree = useAppSelector((decisionTreeVersion) =>
    decisionTreeVersionSelectors.selectById(decisionTreeVersion, versionId as EntityId),
  )

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0])
      setFileName(event.target.files[0].name)
    }
  }

  const onDrop = (e: React.DragEvent<HTMLElement>) => {
    setFile(e.dataTransfer.files[0])
    setFileName(e.dataTransfer.files[0].name)
  }

  const stopDefaults = (e: React.DragEvent) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const dragEvents = {
    onDragEnter: (e: React.DragEvent) => {
      stopDefaults(e)
      setLabelText(dropLabel)
    },
    onDragLeave: (e: React.DragEvent) => {
      stopDefaults(e)
      setLabelText(hoverLabel)
    },
    onDragOver: stopDefaults,
    onDrop: (e: React.DragEvent<HTMLElement>) => {
      stopDefaults(e)
      setLabelText(hoverLabel)
      onDrop(e)
    },
  }

  const exportEmptyTestFile = () => {
    const apiCall = createApiCall('TEST_SAMPLE_FILE', 'GET', `/TestDecisionTree/${versionId}/sampleFile`, undefined)
    const header: { [key: string]: string } = {}
    dispatchApi(apiCall).then((response) => {
      response.data.forEach((element: string) => {
        header[element] = ''
      })
      const name = 'TEST_SAMPLE_FILE.xlsx'
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([header])
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      /* save to file */
      XLSX.writeFile(wb, name)
    })
  }
  const getExceptValueKey = (firstResult: ITestResult) => {
    const keys: Array<string> = []
    Object.keys(firstResult.expectedOutput).forEach((key) => {
      keys.push(key)
    })
    setExceptKey(keys)
  }

  const test = () => {
    const formdata = new FormData()
    formdata.append('testfile', file)
    const apiCall = createApiCall('TEST_SAMPLE_FILE', 'POST', `/TestDecisionTree/${versionId}`, undefined, formdata)
    dispatchApi(apiCall).then((response) => {
      setTestResult(response.data)
      if (response.data.length > 0) getExceptValueKey(response.data[0])
    })
  }

  useEffect(() => {
    dispatchApi(decisionTreeVersionApi.fetchAll(treeId))
  }, [dispatchApi, treeId])

  const onShowTrace = (data: any) => {
    setExecutionTrace(data)
    setShowTrace(true)
  }

  const OncloseTrace = () => {
    setExecutionTrace(undefined)
    setShowTrace(false)
  }

  return (
    <div style={{ height: '100%' }}>
      {showTrace && executionTrace && decisionTree?.steps ? (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              variant="contained"
              sx={{ mr: 1, position: 'fixed' }}
              startIcon={<ArrowBackIcon />}
              onClick={OncloseTrace}
            >
              BACK
            </Button>
          </Box>
          <Trace tree={JSON.parse(JSON.stringify(decisionTree?.steps)) as TreeNode[]} trace={executionTrace} />
        </>
      ) : (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <input onChange={onChange} accept="xlsx" hidden id="file-upload" type="file" />
          <label htmlFor="file-upload" {...dragEvents}>
            <PageError error={pageError} />
            <Box>
              <CloudUploadIcon fontSize="large" />
              <Typography>{labelText}</Typography>
              <Typography color="green">{fileName}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button variant="contained" disableElevation color="inherit" sx={{ mr: 1 }} onClick={exportEmptyTestFile}>
                Export sample file
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button variant="contained" sx={{ mr: 1 }} disabled={file === undefined} onClick={test}>
                TEST
              </Button>
            </Box>
            <TableContainer sx={{ maxHeight: 650, marginTop: 5 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center"> </StyledTableCell>
                    <StyledTableCell align="left">Description</StyledTableCell>
                    <StyledTableCell align="center">Errors</StyledTableCell>
                    <StyledTableCell align="center">Successful</StyledTableCell>
                    {exceptKey.map((except) => (
                      <>
                        <StyledTableCell align="center" key={'excepted.'.concat(except)}>
                          {'excepted.'.concat(except)}
                        </StyledTableCell>
                        <StyledTableCell align="center" key={'output.'.concat(except)}>
                          {'output.'.concat(except)}
                        </StyledTableCell>
                      </>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {testResult.map((result) => (
                    <StyledTableRow key={result.sequence}>
                      <StyledTableCell align="center">
                        <Button variant="contained" endIcon={<AltRouteIcon />} onClick={() => onShowTrace(result)}>
                          Trace
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell align="left">{result.name}</StyledTableCell>
                      <StyledTableCell align="center">{result.error}</StyledTableCell>
                      <StyledTableCell align="center">{result.isSuccess.toString()}</StyledTableCell>
                      {exceptKey.map((except) => (
                        <>
                          <StyledTableCell align="center">{_.get(result.expectedOutput, except, null)}</StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor:
                                _.get(result.expectedOutput, except, null) !==
                                  _.get(result.outputValues, except, null) && !result.isSuccess
                                  ? 'red'
                                  : undefined,
                            }}
                            align="center"
                          >
                            {_.get(result.outputValues, except, null)}
                          </StyledTableCell>
                        </>
                      ))}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </label>
        </div>
      )}
    </div>
  )
}

export default index
